import { fabric } from 'fabric';
import { message } from 'antd';

import { commonUtilities } from 'utils';

import { defaults } from './settings';

const { isNotEmptyArray } = commonUtilities;

const setCanvasSize = (canvas, img) => {
  if (img.height > img.width) {
    canvas.setHeight(defaults.initCanvas.maxSize);
    canvas.setWidth((img.width * defaults.initCanvas.maxSize) / img.height);
  } else {
    canvas.setWidth(defaults.initCanvas.maxSize);
    canvas.setHeight((img.height * defaults.initCanvas.maxSize) / img.width);
  }
  return canvas;
};

const scaleImgToCanvas = img => {
  const canvMaxSize = defaults.initCanvas.maxSize;
  return img.width > img.height ? img.scaleToWidth(canvMaxSize) : img.scaleToHeight(canvMaxSize);
};

export const canvasFromImageUrl = (id, imgUrl, setCustomImageSize, isEditable) => {
  const canvas = new fabric.Canvas(id, {
    ...defaults.initCanvas,
  });

  if (!isEditable) {
    canvas.selection = false;
    canvas.defaultCursor = 'default';
  }

  fabric.Image.fromURL(imgUrl, img => {
    img = scaleImgToCanvas(img);
    setCanvasSize(canvas, img);
    setCustomImageSize({ x: img.aCoords.br.x, y: img.aCoords.br.y });
    canvas.backgroundImage = img;
    canvas.renderAll();
  });
  return canvas;
};

export const handleZoomEvent = (canv1, canv2) => opt => {
  const delta = opt.e.deltaY;
  let zoom = canv1.getZoom();
  zoom *= 0.999 ** delta;
  if (zoom > 20) zoom = 20;
  if (zoom < 0.01) zoom = 0.01;
  canv1.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
  canv2.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
  opt.e.preventDefault();
  opt.e.stopPropagation();
};

export const handleDragMouseDown = (canv1, canv2) => opt => {
  const evt = opt.e;
  if (evt.altKey && evt.shiftKey) {
    canv1.discardActiveObject();
    canv2.discardActiveObject();
    canv1.setCursor('grabbing');
    canv2.setCursor('grabbing');
    canv1.isDragging = true;
    canv1.selection = false;
    canv1.lastPosX = evt.clientX;
    canv1.lastPosY = evt.clientY;
    canv2.isDragging = true;
    canv2.selection = false;
    canv2.lastPosX = evt.clientX;
    canv2.lastPosY = evt.clientY;
  }
};

export const handleDragMouseMove = (canv1, canv2) => opt => {
  if (canv1.isDragging || canv2.isDragging) {
    const { e } = opt;
    const vpt1 = canv1.viewportTransform;
    const vpt2 = canv2.viewportTransform;
    vpt1[4] += e.clientX - canv1.lastPosX;
    vpt1[5] += e.clientY - canv1.lastPosY;
    vpt2[4] += e.clientX - canv2.lastPosX;
    vpt2[5] += e.clientY - canv2.lastPosY;
    canv1.requestRenderAll();
    canv2.requestRenderAll();
    canv1.lastPosX = e.clientX;
    canv1.lastPosY = e.clientY;
    canv2.lastPosX = e.clientX;
    canv2.lastPosY = e.clientY;
  }
};

export const handleDragMouseUp = (canv1, canv2) => () => {
  canv1.setViewportTransform(canv1.viewportTransform);
  canv1.isDragging = false;
  canv1.selection = true;
  canv2.setViewportTransform(canv2.viewportTransform);
  canv2.isDragging = false;
  canv2.selection = true;
};

const updateCanvases = (canv1, canv2) => {
  canv1.renderAll();
  canv2.renderAll();
};

const getRectSize = rectOptions => {
  const { minSize } = defaults;
  const { width, height } = rectOptions;
  return {
    width: width < minSize ? minSize : width || minSize,
    height: height < minSize ? minSize : height || minSize,
  };
};

const checkIntersectedRects = (canv1, canv2, setIntersected) => {
  const activeCanvas = isNotEmptyArray(canv1.getActiveObjects()) ? canv1 : canv2;
  const objects = activeCanvas.getObjects();

  let isIntersected = false;
  objects.forEach((obj, i) => {
    const isIntersectedZone = objects.some((o, j) =>
      i !== j ? obj.intersectsWithObject(o) : false,
    );
    obj.set('fill', isIntersectedZone ? 'rgba(255, 77, 79, 0.5)' : 'transparent');
    if (!isIntersected) {
      isIntersected = isIntersectedZone;
    }
  });
  setIntersected(isIntersected);
};

export const addRectangle = (canvases, settings, setIntersected = () => {}) => {
  const [canv1, canv2] = canvases;
  const { isEditable = true, isNewRect = false, rectOptions } = settings;

  const rect = new fabric.Rect({
    ...defaults.rectOptions,
    ...rectOptions,
    ...(!isEditable && defaults.notEditableRectOptions),
  });
  rect.setControlsVisibility(defaults.rectControlsVisibility);

  rect.on({
    moving: () => checkIntersectedRects(canv1, canv2, setIntersected),
    scaling: () => {
      if (rect.width * rect.scaleX < defaults.minSize) {
        rect.set({
          width: defaults.minSize,
          height: defaults.minSize,
          scaleX: defaults.minScale,
          scaleY: defaults.minScale,
        });
      } else {
        const activeCanvas = isNotEmptyArray(canv1.getActiveObjects()) ? canv1 : canv2;
        activeCanvas.getActiveObjects().forEach(obj => {
          obj.set(getRectSize({ width: obj.width * obj.scaleX, height: obj.height * obj.scaleY }));
          updateCanvases(canv1, canv2);
        });
      }
      checkIntersectedRects(canv1, canv2, setIntersected);
    },
    // activation/deactivation makes resizing active on both canvases for all rectangles
    selected: () => {
      canv1.setActiveObject(rect);
      canv2.setActiveObject(rect);
    },
    deselected: () => {
      canv1.discardActiveObject(rect);
      canv2.discardActiveObject(rect);
    },
  });

  if (isNewRect) {
    canv1.setActiveObject(rect);
    canv2.setActiveObject(rect);
  }

  canv2.add(rect);
  canv1.add(rect);
  checkIntersectedRects(canv1, canv2, setIntersected);
  updateCanvases(canv1, canv2);
};

export const deleteSelectedRectangles = (canv1, canv2, setIntersected) => {
  const activeCanvas = isNotEmptyArray(canv1.getActiveObjects()) ? canv1 : canv2;
  activeCanvas.getActiveObjects().forEach(obj => {
    canv1.remove(obj);
    canv2.remove(obj);
  });
  checkIntersectedRects(canv1, canv2, setIntersected);
  canv1.discardActiveObject().renderAll();
  canv2.discardActiveObject().renderAll();
};

export const handleAddRectangleMouseDown = (opt, canv) => {
  const evt = opt.e;
  if (evt.ctrlKey === true && !evt.altKey) {
    canv.isRectangleAdding = true;
    canv.initPosX = evt.offsetX;
    canv.initPosY = evt.offsetY;
  }
};

const correctZonePosition = (canv1, canv2) => {
  const activeCanvas = isNotEmptyArray(canv1.getActiveObjects()) ? canv1 : canv2;
  activeCanvas.getActiveObjects().forEach(obj => {
    const isZoneOutOfLeft = obj.aCoords.bl.x < 0;
    const isZoneOutOfRight = obj.aCoords.br.x > obj.canvas.width;
    const isZoneOutOfTop = obj.aCoords.tl.y < 0;
    const isZoneOutOfBottom = obj.aCoords.br.y > obj.canvas.height;

    if (isZoneOutOfLeft) {
      obj.set({ left: 0 });
    } else if (isZoneOutOfRight) {
      obj.set({ left: obj.canvas.width - obj.width - obj.strokeWidth });
    }
    if (isZoneOutOfTop) {
      obj.set({ top: 0 });
    } else if (isZoneOutOfBottom) {
      obj.set({ top: obj.canvas.height - obj.height - obj.strokeWidth });
    }

    const isZoneOutOfImage =
      isZoneOutOfLeft || isZoneOutOfRight || isZoneOutOfTop || isZoneOutOfBottom;
    if (isZoneOutOfImage) {
      message.warning("Zone can't be located outside the image.");
    }
  });
};

export const handleAddRectangleMouseUp = (
  opt,
  canv1,
  canv2,
  defineZonesQuantity,
  setIntersected = () => {},
) => {
  const evt = opt.e;
  const zonesLength = canv1.toObject(['cornerColor']).objects.length;
  const isDrawingZoneModeOn = evt.ctrlKey === true && !evt.altKey && zonesLength <= 4;

  correctZonePosition(canv1, canv2);

  if (isDrawingZoneModeOn) {
    canv1.isRectangleAdding = false;
    canv1.lastPosX = evt.offsetX;
    canv1.lastPosY = evt.offsetY;

    const height = Math.abs(canv1.initPosY - canv1.lastPosY);
    const width = Math.abs(canv1.initPosY - canv1.lastPosY);
    const left = Math.min(canv1.initPosX, canv1.lastPosX);
    const top = Math.min(canv1.initPosY, canv1.lastPosY);

    const { width: correctedWidth, height: correctedHeight } = getRectSize({ width, height });

    const zonePositionRight = left + correctedWidth;
    const zonePositionBottom = top + correctedHeight;

    const isDrawingZoneNotOutOfImage =
      zonePositionRight <= canv1.width && zonePositionBottom <= canv1.height;

    if (isDrawingZoneNotOutOfImage) {
      addRectangle(
        [canv1, canv2],
        {
          isEditable: true,
          isNewRect: true,
          rectOptions: {
            stroke: canv1.stroke,
            cornerColor: canv1.cornerColor,
            width: correctedWidth,
            height: correctedHeight,
            left,
            top,
          },
        },
        setIntersected,
      );
      defineZonesQuantity();
    }
  }
};
