export const updatingGameForm = {
  settings: {
    layout: 'vertical',
    name: 'updating-game',
    validateTrigger: 'onSubmit',
  },
  nameField: {
    name: 'name',
    label: 'Game Name',
    initialValue: '',
    rules: [
      { required: true, message: 'The name cannot be blank' },
      {
        max: 64,
        message: 'The name is too long. Maximum is 64',
      },
    ],
  },
  descriptionField: {
    name: 'description',
    label: 'Description',
    initialValue: '',
    rules: [{ required: true, message: 'The description cannot be blank' }],
  },
  withPrizeField: {
    name: 'withPrize',
    valuePropName: 'checked',
  },
  prizeDescriptionField: {
    name: 'prizeDescription',
    label: 'Game Prize Description',
    initialValue: '',
    rules: [{ required: true, message: "The prize's description cannot be blank" }],
  },
  prizeConditionsField: {
    name: 'prizeConditions',
    label: "Prize's Condition",
    initialValue: '',
    rules: [{ required: true, message: "You should chose a prize's condition" }],
  },
  usersCountField: {
    name: 'usersCount',
    label: 'Users',
    initialValue: 1,
    rules: [{ required: true, message: 'Minimum is 1' }],
  },
  redeemingPrizeField: {
    name: 'redeemingPrize',
    label: 'Conditions of Redeeming the Prize',
    initialValue: '',
    rules: [{ required: true, message: 'Condition of redeeming the prize cannot be blank' }],
  },
  submitButton: {
    type: 'primary',
    htmlType: 'submit',
  },
};
