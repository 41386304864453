import React, { useState } from 'react';
import { Layout as AntLayout } from 'antd';
import { Redirect } from 'react-router-dom';

import Trigger from './Trigger';
import Sidebar from './Sidebar';
import ProfileMenu from './ProfileMenu';
import { Header, Content } from './styles';

const Layout = ({ canActivate, children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(value => !value);
  };

  if (!canActivate) {
    return <Redirect to="/login" />;
  }

  return (
    <AntLayout>
      <Sidebar collapsed={collapsed} />
      <AntLayout>
        <Header>
          <Trigger collapsed={collapsed} onClick={toggle} />
          <ProfileMenu />
        </Header>
        <Content>{children}</Content>
      </AntLayout>
    </AntLayout>
  );
};

export default Layout;
