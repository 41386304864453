import { format } from 'date-fns';
import { GiftOutlined } from '@ant-design/icons';

export const userKey = 'users_permissions_user.username_contains';

const prizeStatuses = {
  true: {
    text: 'Prize',
  },
  false: {
    text: 'No prize',
  },
};

export const columns = [
  {
    key: 'rating',
    dataIndex: 'rating',
    align: 'center',
  },
  {
    key: userKey,
    title: 'Username',
    dataIndex: 'users_permissions_user',
  },
  {
    key: 'created_at',
    dataIndex: 'created_at',
    align: 'center',
    render: date => format(new Date(date).getTime(), 'hh:mm MM/dd/yy'),
  },
  {
    key: 'averagescore',
    title: 'Score',
    dataIndex: 'averagescore',
    align: 'center',
    sorter: {},
  },
  {
    key: 'prize',
    title: 'Prize score',
    dataIndex: 'prize',
    align: 'center',
    render: prize =>
      prize ? (
        <div>
          <span style={{ marginRight: 4 }}>{prize?.score || ''}</span>
          <GiftOutlined style={{ color: '#9c0000' }} />
        </div>
      ) : null,
    filters: Object.keys(prizeStatuses).map(key => {
      const { text, color } = prizeStatuses[key];
      return { value: key, text, color };
    }),
  },
];

export const initSorter = {
  field: 'averagescore',
  order: 'descend',
};
