import { commonUtilities } from 'utils';

export const loginForm = {
  settings: {
    name: 'login',
    validateTrigger: 'onSubmit',
  },
  emailField: {
    name: 'identifier',
    initialValue: '',
    rules: [
      { required: true, message: 'The email field cannot be blank' },
      {
        type: 'email',
        message: 'The format of email is incorrect',
      },
    ],
    normalize: value => value.trim(),
  },
  passwordField: {
    name: 'password',
    initialValue: '',
    rules: [
      { required: true, message: 'The password field cannot be blank' },
      {
        min: 8,
        message: "Password can't be less than 8 characters.",
      },
      { max: 24, message: "Password can't be longer than 24 characters" },
      {
        pattern: commonUtilities.passwordRegExp(),
        message: 'Password must have at least one digit, uppercase and lowercase symbol',
      },
    ],
  },
  submitButton: {
    type: 'primary',
    htmlType: 'submit',
    block: true,
  },
};
