import styled from 'styled-components';

import loginBG from 'assets/images/login.jpg';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-image: url(${loginBG});
  background-size: cover;
  background-position: center;

  form {
    width: 320px;
  }

  button[type='submit'] {
    height: 40px;
    font-size: 16px;
  }
`;
