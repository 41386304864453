import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import { SettingOutlined } from '@ant-design/icons';

import api from 'services/API';
import { commonUtilities } from 'utils';
import { useQuery, useQueryParams } from 'hooks';

import { Button, Table, Pagination } from 'components';
import { ReactComponent as TrophyIcon } from 'assets/images/trophy.svg';
import { columns, creatorKey, initSorter } from './settings';

const { isNotEmptyArray } = commonUtilities;

const Games = () => {
  const { data: fetchedGames, refetch } = useQuery(
    'games',
    async () =>
      await api.getGames(queryParams).then(({ data, pagination }) => {
        setPagination(pagination);
        return data;
      }),
  );

  const {
    pagination,
    setPagination,
    filters,
    sorter,
    queryParams,
    handleChangeTable,
    clearFilters,
  } = useQueryParams(refetch, { initSorter });

  const games = useMemo(
    () =>
      isNotEmptyArray(fetchedGames) ? fetchedGames.map(game => ({ ...game, key: game.id })) : [],
    [fetchedGames],
  );

  const tableColumns = useCallback(
    () =>
      columns.map(column => {
        column.filteredValue = (filters && filters[column.key]) || null;

        if (
          (isNotEmptyArray(sorter) && !!sorter.find(({ field }) => field === column.key)) ||
          sorter.field === column.key
        ) {
          const columnSorter = isNotEmptyArray(sorter)
            ? { ...sorter.find(({ field }) => field === column.key) }
            : sorter;
          column.defaultSortOrder = columnSorter.order;
          column.sorter = { ...columnSorter, multiple: column.sorter.multiple };
        }

        if (column.key === creatorKey) {
          const searchWords = !isEmpty(queryParams._where) && queryParams._where[creatorKey];
          return {
            ...column,
            render: ({ username }) =>
              isNotEmptyArray(searchWords) ? (
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={searchWords}
                  autoEscape
                  textToHighlight={username ? username.toString() : ''}
                />
              ) : (
                username
              ),
          };
        } else if (column.key === 'settings') {
          return {
            ...column,
            render: ({ id }) => (
              <Link
                to={{
                  pathname: `/games/${id}`,
                  queryParams,
                }}
                style={{ fontSize: 21 }}
              >
                <SettingOutlined />
              </Link>
            ),
          };
        } else if (column.key === 'leaderboard') {
          return {
            ...column,
            render: ({ id }) => (
              <Link
                to={{
                  pathname: `/games/${id}/leaderboard`,
                  prevQueryParams: queryParams,
                }}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <TrophyIcon className="ant-tag-warning" />
              </Link>
            ),
          };
        }
        return column;
      }),
    [filters, sorter, queryParams],
  );

  return (
    <>
      <div className="page-controls">
        <Button type="danger" onClick={clearFilters} disabled={isEmpty(pickBy(filters, identity))}>
          Clear filters
        </Button>
        <Pagination
          {...pagination}
          onChange={(current, pageSize) =>
            handleChangeTable({ current, pageSize }, filters, sorter)
          }
        />
      </div>
      <Table
        columns={tableColumns()}
        dataSource={games}
        pagination={{ ...pagination, position: ['bottomRight'] }}
        onChange={handleChangeTable}
      />
    </>
  );
};

export default Games;
