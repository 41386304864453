export const initPagination = { page: 1, pageSize: 15 };

const defaultPageSize = initPagination.pageSize;

const pagination = {
  size: 'default',
  defaultPageSize: defaultPageSize,
  pageSizeOptions: [defaultPageSize, defaultPageSize * 2, defaultPageSize * 4, 100],
  showSizeChanger: true,
  showQuickJumper: true,
};

export default pagination;
