const timeUtilities = {
  fromMSToHours: ms => {
    const minutes = Math.floor(ms / 1000 / 60);
    const hours = Math.floor(minutes / 60);
    return hours + (minutes % 60) / 60;
  },
  fromMSToMinutes: ms => Math.floor(ms / 1000 / 60),
  fromHoursToMS: hours => Math.floor(hours * 60 * 60 * 1000),
  fromMinutesToMS: minutes => Math.floor(minutes * 60 * 1000),
};

export default timeUtilities;
