import { Table as AntTable } from 'antd';
import isEmpty from 'lodash/isEmpty';

import paginationConfig from 'config/pagination';

const Table = ({ size = 'small', pagination = {}, ...restProps }) => (
  <AntTable
    size={size}
    pagination={
      isEmpty(pagination)
        ? false
        : {
            ...paginationConfig,
            current: pagination.page,
            pageSize: pagination.pageSize,
            total: pagination.rowCount,
            position: pagination.position,
          }
    }
    {...restProps}
  />
);

export default Table;
