import httpClient from 'services/httpClient';

const API = {
  login(payload) {
    return httpClient.post('/admins/login', payload);
  },
  confirmRegistration(payload) {
    return httpClient.post('/admins/confirm', payload);
  },
  getAdmins(params) {
    return httpClient.get('/admins', { params });
  },
  inviteAdmin(payload) {
    return httpClient.post('/admins/invite', payload);
  },
  reInviteAdmin(payload) {
    return httpClient.post('/admins/invite/resend', payload);
  },
  getUsers(params) {
    return httpClient.get('/users', { params });
  },
  deleteUser(id) {
    return httpClient.delete(`/users/${id}`);
  },
  getGames(params) {
    return httpClient.get('/all-games', { params });
  },
  getGame(id) {
    return httpClient.get('/admin-game-details/:id', {
      requestParams: { id },
    });
  },
  updateGameStatus(id, payload) {
    return httpClient.put('/game-status/:id', payload, {
      requestParams: { id },
    });
  },
  updateGame(id, payload) {
    return httpClient.put(`/games/${id}`, payload);
  },
  getPrizeConditions() {
    return httpClient.get('/prize-conditions');
  },
  uploadImage(payload) {
    return httpClient.post('/upload/game-image', payload);
  },
  addLevelImage(payload) {
    return httpClient.post('/level-images', payload);
  },
  updateLevelImage(id, payload) {
    return httpClient.put(`/level-image-mapping/${id}`, payload);
  },
  deleteLevelImage(id) {
    return httpClient.delete(`/level-images/${id}`);
  },
  deleteGame(id) {
    return httpClient.delete(`/admin-games/${id}`);
  },
  getImages(params) {
    return httpClient.get('level-images', { params });
  },
  getGameLeaders(id, params) {
    return httpClient.get(`game/${id}/game-leaders`, { params });
  },
};

export default API;
