import axios from 'axios';

const commonUtilities = {
  isNotEmptyArray: data => Array.isArray(data) && data.length > 0,
  passwordRegExp: () => new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/),
  downloadImage: async imageSrc => {
    const { data } = await axios.get(`${imageSrc}?timestamp=${Date.now()}`, {
      responseType: 'blob',
    });
    const imageURL = URL.createObjectURL(data);

    const link = document.createElement('a');
    const imageUrlParts = imageSrc.split('/');
    const fileName = imageUrlParts[imageUrlParts.length - 1];
    link.href = imageURL;
    link.download = fileName;
    link.click();
  },
};

export default commonUtilities;
