export const columns = [
  {
    key: 'username',
    title: 'Admin Name',
    dataIndex: 'username',
  },
  {
    key: 'email',
    title: 'Email',
    dataIndex: 'email',
    sorter: {},
  },
  {
    key: 'confirmed',
    title: 'Status',
    dataIndex: 'confirmed',
    align: 'center',
    render: confirmed => (confirmed ? 'Active' : 'Inactive'),
    sorter: {},
  },
  {
    key: 'invitation',
    title: 'Invitation',
    dataIndex: 'confirmed',
    align: 'center',
  },
];

export const initSorter = {
  field: 'email',
  order: 'ascend',
};

export const invitationForm = {
  settings: {
    name: 'invitation',
    validateTrigger: 'onSubmit',
  },
  emailField: {
    name: 'email',
    initialValue: '',
    rules: [
      { required: true, message: 'The email field cannot be blank' },
      {
        type: 'email',
        message: 'The format of email is incorrect',
      },
    ],
    normalize: value => value.trim(),
  },
};
