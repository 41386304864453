import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { menu } from './settings';
import { Logo } from './styles';

const { Sider } = Layout;

const Sidebar = ({ collapsed }) => {
  const { pathname } = useLocation();
  const selectedKey = menu.find(({ link }) => pathname.includes(link)).link;

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <Logo>spotmy</Logo>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[selectedKey]}
        selectedKeys={[selectedKey]}
      >
        {menu.map(item => (
          <Menu.Item key={item.link} icon={item.icon}>
            <Link to={item.link}>{item.title}</Link>
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
