import { useCallback, useEffect, useState } from 'react';
import { Divider, Form, Input, Tag } from 'antd';

import { Button } from 'components';

import * as utils from './utils';
import { defaults, arrows } from './settings';
import { Wrapper, Arrows, Controls, Footer } from './styles';

/**
 * @param {Object} properties - properties for canvases initializing
 * @param {string} properties.img1Url - first image url
 * @param {string} properties.img2Url - second image url
 * @param {Object[]} [properties.rectangles] - array of predefined rectangles
 * @param {number} [rectangles[].top] - y coordinate of a rectangle's top left corner
 * @param {number} [rectangles[].left] - x coordinate of a rectangle's top left corner
 * @param {height} [rectangles[].height] - height of a rectangle
 * @param {width} [rectangles[].width] - width of a rectangle
 * @param {string} [rectangles[].stroke] - stroke color of a rectangle
 * @param {string} [rectangles[].cornerColor] - corner color of a rectangle
 */
const ImageMapper = ({ img1Url, img2Url, rectangles, handleUpdateMapping, isEditable = true }) => {
  const [rectanglesInitialized, setRectanglesInitialized] = useState(false);
  const [isAddingRectanglesSet, setIsAddingRectanglesSet] = useState(false);
  const [firstCanvas, setFirstCanvas] = useState();
  const [secondCanvas, setSecondCanvas] = useState();
  const [customImageSize, setCustomImageSize] = useState(null);
  const [stroke, setStroke] = useState(defaults.rectOptions.stroke);
  const [cornerColor, setCornerColor] = useState(defaults.rectOptions.cornerColor);
  const [zonesQuantity, setZonesQuantity] = useState(0);
  const [intersected, setIntersected] = useState(false);

  const hasRequiredZonesQuantity = zonesQuantity > 4;

  const setZoomEvents = useCallback((canv1, canv2) => {
    canv1.on('mouse:wheel', utils.handleZoomEvent(canv1, canv2));
    canv2.on('mouse:wheel', utils.handleZoomEvent(canv2, canv1));
  }, []);

  const setDragEvents = useCallback((canv1, canv2) => {
    canv1.on({
      'mouse:down': utils.handleDragMouseDown(canv1, canv2),
      'mouse:move': utils.handleDragMouseMove(canv1, canv2),
      'mouse:up': utils.handleDragMouseUp(canv1, canv2),
    });
    canv2.on({
      'mouse:down': utils.handleDragMouseDown(canv2, canv1),
      'mouse:move': utils.handleDragMouseMove(canv2, canv1),
      'mouse:up': utils.handleDragMouseUp(canv2, canv1),
    });
  }, []);

  const defineZonesQuantity = useCallback(() => {
    const zonesLength = firstCanvas.toObject(['cornerColor']).objects.length;
    setZonesQuantity(zonesLength);
  }, [firstCanvas]);

  const setAddingRectangleEvents = useCallback(
    (canv1, canv2) => {
      if (!isAddingRectanglesSet && isEditable) {
        canv1.on({
          'mouse:down': opt => utils.handleAddRectangleMouseDown(opt, canv1),
          'mouse:up': opt =>
            utils.handleAddRectangleMouseUp(opt, canv1, canv2, defineZonesQuantity, setIntersected),
        });
        canv2.on({
          'mouse:down': opt => utils.handleAddRectangleMouseDown(opt, canv2),
          'mouse:up': opt =>
            utils.handleAddRectangleMouseUp(opt, canv2, canv1, defineZonesQuantity, setIntersected),
        });
      } else {
        defineZonesQuantity();
      }
      setIsAddingRectanglesSet(true);
    },
    [isAddingRectanglesSet, isEditable, defineZonesQuantity],
  );

  const initRectangles = useCallback(
    (canv1, canv2, rectangles) => {
      if (rectangles?.length && !rectanglesInitialized && customImageSize) {
        rectangles.forEach(rectOpt => {
          const { left, top, width, height, ...restOptions } = rectOpt;
          utils.addRectangle(
            [canv1, canv2],
            {
              isEditable,
              rectOptions: {
                ...restOptions,
                left: left * customImageSize.x,
                top: top * customImageSize.y,
                width: width * customImageSize.x,
                height: height * customImageSize.y,
              },
            },
            setIntersected,
          );
        });
        setRectanglesInitialized(true);
      }
    },
    [rectanglesInitialized, isEditable, customImageSize],
  );

  useEffect(() => {
    const canv1 = utils.canvasFromImageUrl(
      defaults.img1Id,
      img1Url,
      setCustomImageSize,
      isEditable,
    );
    const canv2 = utils.canvasFromImageUrl(
      defaults.img2Id,
      img2Url,
      setCustomImageSize,
      isEditable,
    );

    setZoomEvents(canv1, canv2);
    setDragEvents(canv1, canv2);

    setFirstCanvas(canv1);
    setSecondCanvas(canv2);
  }, [img1Url, img2Url, rectangles, setZoomEvents, setDragEvents, isEditable]);

  useEffect(() => {
    if (firstCanvas && secondCanvas) {
      initRectangles(firstCanvas, secondCanvas, rectangles);
      setAddingRectangleEvents(firstCanvas, secondCanvas);
      firstCanvas.defaultCursor = hasRequiredZonesQuantity ? 'default' : 'crosshair';
      secondCanvas.defaultCursor = hasRequiredZonesQuantity ? 'default' : 'crosshair';
    }
  }, [
    firstCanvas,
    secondCanvas,
    rectangles,
    initRectangles,
    setAddingRectangleEvents,
    hasRequiredZonesQuantity,
  ]);

  useEffect(() => {
    if (firstCanvas && secondCanvas) {
      firstCanvas.stroke = stroke;
      secondCanvas.stroke = stroke;
      firstCanvas.cornerColor = cornerColor;
      secondCanvas.cornerColor = cornerColor;
    }
  }, [firstCanvas, secondCanvas, stroke, cornerColor]);

  const moveImage = (x, y) => {
    const delta = { x, y };
    firstCanvas.relativePan(delta);
    secondCanvas.relativePan(delta);
  };

  const updateStrokeColor = e => {
    setStroke(e.target.value);
  };
  const updateCornerColor = e => {
    setCornerColor(e.target.value);
  };

  const handleAddRectangle = () => {
    utils.addRectangle(
      [firstCanvas, secondCanvas],
      {
        isEditable: true,
        isNewRect: true,
        rectOptions: { stroke, cornerColor },
      },
      setIntersected,
    );
    defineZonesQuantity();
  };

  const handleDeleteRectangles = () => {
    utils.deleteSelectedRectangles(firstCanvas, secondCanvas, setIntersected);
    defineZonesQuantity();
  };

  const saveChanges = () => {
    handleUpdateMapping(
      firstCanvas
        .toObject(['cornerColor'])
        .objects.map(({ top, left, width, height, stroke, cornerColor }) => ({
          left: left / customImageSize.x,
          top: top / customImageSize.y,
          width: width / customImageSize.x,
          height: height / customImageSize.y,
          stroke,
          cornerColor,
        })),
    );
  };

  return (
    <Wrapper>
      <div className="top-controls">
        <div className="info-wrapper">
          {isEditable && (
            <div className="info">
              <Tag color="geekblue">Ctrl</Tag> - drawing zone
            </div>
          )}
          <div className="info">
            <Tag color="geekblue">Alt + Shift</Tag> - dragging image
          </div>
        </div>
        {isEditable && (
          <Controls>
            <Form layout="inline">
              <Form.Item label="Stroke">
                <Input name="stroke" type="color" value={stroke} onChange={updateStrokeColor} />
              </Form.Item>
              <Form.Item label="Corner">
                <Input
                  name="corner"
                  type="color"
                  value={cornerColor}
                  onChange={updateCornerColor}
                />
              </Form.Item>
            </Form>
            <Button type="primary" disabled={hasRequiredZonesQuantity} onClick={handleAddRectangle}>
              Add Zone
            </Button>
            <Button danger style={{ marginLeft: 16 }} onClick={handleDeleteRectangles}>
              Delete Selected Zone
            </Button>
          </Controls>
        )}
      </div>
      <div className="images">
        <canvas id={defaults.img1Id} />
        <Arrows>
          {arrows.map(({ icon, className, coords }, index) => (
            <Button
              key={index}
              type="primary"
              shape="circle"
              size="large"
              icon={icon}
              className={className}
              onClick={() => moveImage(...coords)}
            />
          ))}
        </Arrows>
        <canvas id={defaults.img2Id} />
      </div>
      {isEditable && (
        <>
          <Divider />
          <Footer>
            <Button
              type="primary"
              disabled={zonesQuantity !== 5 || intersected}
              title={zonesQuantity !== 5 || intersected ? 'You should mark only 5 zones.' : ''}
              onClick={saveChanges}
            >
              Save changes
            </Button>
          </Footer>
        </>
      )}
    </Wrapper>
  );
};

export default ImageMapper;
