import { useState, useMemo } from 'react';
import { Image as AntImage, Divider, List, Upload, Typography } from 'antd';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';

import api from 'services/API';
import { commonUtilities } from 'utils';
import { gameStatuses, notification } from 'config';
import { ImageMapper, Button } from 'components';

import { LevelsWrapper, OriginalImage, CustomImages, MappingZoneModal } from './styles';

const Levels = ({ game, setGame, refetch }) => {
  const [currentLevelImage, setCurrentLevelImage] = useState(null);

  const handlePreview = async (levelId, originalImage, customImage) => {
    setCurrentLevelImage({
      levelId,
      originalImage,
      customImage,
    });
  };

  const validateImagesSize = (originImageUrl, customImage, uploadImage) => {
    const originImg = new Image();
    originImg.src = originImageUrl;
    originImg.onload = () => {
      const customImageURL = URL.createObjectURL(customImage);
      const customImg = new Image();
      customImg.src = customImageURL;
      customImg.onload = () =>
        uploadImage(originImg.width === customImg.width && originImg.height === customImg.height);
    };
  };

  const handleUpload = async (levelId, originalImage, file) => {
    const uploadImage = async isIdentity => {
      if (isIdentity) {
        const data = new FormData();
        data.append('files', file);
        const uploadedImages = await api.uploadImage(data);
        setCurrentLevelImage({ levelId, originalImage, customImage: uploadedImages[0] });
      } else {
        notification.warning({
          message: `Uploaded image doesn't correspond to the original image's size.`,
        });
      }
    };

    validateImagesSize(originalImage.url, file, uploadImage);
  };

  const deleteLevelImage = async ({ id }) => {
    const deletedImage = await api.deleteLevelImage(id);
    setGame({
      ...game,
      levels: game.levels.map(level => {
        if (Number(level.id) === deletedImage.level.id) {
          return { ...level, level_images: level.level_images.filter(img => img.id !== id) };
        }
        return level;
      }),
    });
  };

  const updateLevelImage = async mapping => {
    const isImageNew = !commonUtilities.isNotEmptyArray(currentLevelImage?.customImage?.mapping);
    if (isImageNew) {
      await api.addLevelImage({
        levels: [
          {
            level: currentLevelImage.levelId,
            image: currentLevelImage.customImage.id,
            mapping,
          },
        ],
      });
    } else {
      await api.updateLevelImage(currentLevelImage.customImage.id, mapping);
    }
    refetch();
    setCurrentLevelImage(null);

    notification.success({
      message: `The level was updated successfully`,
    });
  };

  const isImageEditable = useMemo(() => {
    const { ready, rejected, published } = gameStatuses;
    const reviewedGameStatuses = [ready.title, rejected.title, published.title].map(status =>
      status.toLowerCase(),
    );
    const isGameReviewedByAdmin = !reviewedGameStatuses.some(status => status === game.status);
    const isImageNew = !commonUtilities.isNotEmptyArray(currentLevelImage?.customImage?.mapping);
    return isGameReviewedByAdmin || isImageNew;
  }, [currentLevelImage, game.status]);

  const renderedLevel = ({ id: levelId, level_images }, index) => {
    const originalImage = level_images.find(({ type }) => type === 'original');
    const filteredImages = level_images.filter(({ type }) => type === 'custom');
    const images = filteredImages.map(img => {
      const { id, url, mapping } = img;
      return {
        id,
        uid: `${id}`,
        url,
        mapping,
      };
    });

    return (
      <List.Item key={levelId}>
        <Typography.Title level={4}>Level {++index}</Typography.Title>
        <div className="images">
          <OriginalImage>
            <AntImage className="image" width={104} height={104} src={originalImage.url} />
            <Button
              type="primary"
              shape="circle"
              icon={<DownloadOutlined />}
              size="large"
              className="link"
              onClick={() => commonUtilities.downloadImage(originalImage.url)}
            />
          </OriginalImage>
          <CustomImages>
            <Upload
              accept="image/png, image/jpeg"
              listType="picture-card"
              fileList={images}
              onPreview={customImage => handlePreview(levelId, originalImage, customImage)}
              customRequest={({ file }) => handleUpload(levelId, originalImage, file)}
              onRemove={deleteLevelImage}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload>
          </CustomImages>
        </div>
      </List.Item>
    );
  };

  return (
    <LevelsWrapper>
      <Divider orientation="left" style={{ marginBottom: 0 }}>
        <Typography.Title level={3}>Levels</Typography.Title>
      </Divider>
      <List itemLayout="vertical" dataSource={game?.levels || []} renderItem={renderedLevel} />
      {currentLevelImage && (
        <MappingZoneModal
          visible={currentLevelImage}
          title={isImageEditable ? 'Marking Zones' : "Image's zones"}
          okText="Save"
          footer={null}
          onCancel={() => setCurrentLevelImage(null)}
        >
          <ImageMapper
            img1Url={currentLevelImage?.originalImage?.url}
            img2Url={currentLevelImage?.customImage?.url}
            rectangles={currentLevelImage?.customImage?.mapping}
            isEditable={isImageEditable}
            handleUpdateMapping={updateLevelImage}
          />
        </MappingZoneModal>
      )}
    </LevelsWrapper>
  );
};

export default Levels;
