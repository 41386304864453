import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  .ant-form-item {
    margin-bottom: 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;

  .status {
    margin: 0 16px 0 0;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  img {
    object-fit: contain;
  }

  &.brand {
    flex-direction: column;
    height: 100%;
    position: relative;
    margin-top: -50px;

    &::before {
      content: '';
      position: absolute;
      width: 1px;
      height: calc(100% - 14px);
      background: rgba(0, 0, 0, 0.2);
      left: 15px;
      top: 50px;
    }
  }
  &.small {
    margin-bottom: 0;
    .ant-image-mask-info {
      display: none;
    }
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .description {
    flex: 1 0 auto;
  }
  .ant-form-item-control-input,
  .ant-form-item-control-input-content,
  .ant-form-item textarea.ant-input {
    height: 100%;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 16px;
  }
`;
