import { Dropdown, Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { DropdownAvatar } from './styles';

const ProfileMenu = () => {
  const logout = () => {
    localStorage.removeItem('spot_my_admin');
    window.location.reload();
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={logout}>Logout</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown placement="bottomCenter" overlay={menu}>
      <DropdownAvatar icon={<UserOutlined />} />
    </Dropdown>
  );
};

export default ProfileMenu;
