import { Tag } from 'antd';
import { format } from 'date-fns';
import { Typography } from 'antd';
import { EyeOutlined, LikeOutlined } from '@ant-design/icons';

import { gameStatuses } from 'config';
import { FilterSearchIcon, FilterSearchDropdown } from 'components';

export const creatorKey = 'creator.username_contains';

const YES_NO_STATUS_MAPPING = {
  true: {
    text: 'Yes',
    color: 'green',
  },
  false: {
    text: 'No',
    color: 'red',
  },
};

export const columns = [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
    filterIcon: filtered => <FilterSearchIcon filtered={filtered} />,
    filterDropdown: props => (
      <FilterSearchDropdown {...props} searchBy={{ placeholder: 'by ID', number: true }} />
    ),
  },
  {
    key: 'name',
    title: 'Title',
    dataIndex: 'name',
    render: title => (
      <Typography.Paragraph
        ellipsis={{
          rows: 1,
        }}
        title={title}
        style={{ maxWidth: 250, marginBottom: 0 }}
      >
        {title}
      </Typography.Paragraph>
    ),
  },
  {
    key: creatorKey,
    title: 'Username',
    dataIndex: 'creator',
    filterIcon: filtered => <FilterSearchIcon filtered={filtered} />,
    filterDropdown: props => (
      <FilterSearchDropdown {...props} searchBy={{ placeholder: 'creator' }} />
    ),
  },
  {
    key: 'created_at',
    title: 'Date',
    dataIndex: 'created_at',
    align: 'center',
    render: date => format(new Date(date).getTime(), 'MM/dd/yyyy'),
    defaultSortOrder: 'ascend',
    sorter: {},
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: status => (
      <Tag color={gameStatuses[status].color}>{gameStatuses[status].title.toUpperCase()}</Tag>
    ),
    filters: Object.keys(gameStatuses).map(key => ({
      value: key,
      text: gameStatuses[key].title,
    })),
  },
  {
    key: 'withPrize',
    title: 'Prize',
    dataIndex: 'withPrize',
    align: 'center',
    render: hasPrize => (
      <Tag color={YES_NO_STATUS_MAPPING[hasPrize].color}>
        {YES_NO_STATUS_MAPPING[hasPrize].text.toUpperCase()}
      </Tag>
    ),
    filters: Object.keys(YES_NO_STATUS_MAPPING).map(key => {
      const { text, color } = YES_NO_STATUS_MAPPING[key];
      return { value: key, text, color };
    }),
  },
  {
    key: 'sponsored',
    title: 'Sponsored',
    dataIndex: 'sponsored',
    align: 'center',
    render: isSponsored => (
      <Tag color={YES_NO_STATUS_MAPPING[isSponsored].color}>
        {YES_NO_STATUS_MAPPING[isSponsored].text.toUpperCase()}
      </Tag>
    ),
    filters: Object.keys(YES_NO_STATUS_MAPPING).map(key => {
      const { text, color } = YES_NO_STATUS_MAPPING[key];
      return { value: key, text, color };
    }),
  },
  {
    key: 'viewsCount',
    title: <EyeOutlined />,
    dataIndex: 'viewsCount',
    align: 'center',
  },
  {
    key: 'likesCount',
    title: <LikeOutlined />,
    dataIndex: 'likesCount',
    align: 'center',
  },
  {
    key: 'settings',
    align: 'center',
  },
  {
    key: 'leaderboard',
    align: 'center',
  },
];

export const initSorter = {
  field: 'created_at',
  order: 'ascend',
};
