import styled from 'styled-components';

import { Modal } from 'components';

export const LevelsWrapper = styled.div`
  .ant-list-item {
    align-items: flex-start;
  }
  .ant-image-img {
    object-fit: cover;
    &.image {
      min-width: 104px;
    }
  }
  .anticon-delete {
    color: red !important;
  }
  .ant-upload-list-item-card-actions-btn {
    margin-left: 8px;
  }
  .images {
    display: flex;
    align-items: flex-start;
  }
`;

export const OriginalImage = styled.div`
  position: relative;
  .link {
    position: absolute;
    right: -18px;
    bottom: -7px;
    margin: 0 !important;
  }
`;

export const CustomImages = styled.div`
  width: 100%;
  margin-left: 40px;
`;

export const MappingZoneModal = styled(Modal)`
  width: 1192px !important;
  min-width: 1192px;
`;
