import { Pagination as AntPagination } from 'antd';

import { pagination } from 'config';

const Pagination = ({ page, pageSize, rowCount, onChange = () => {}, ...restProps }) => (
  <AntPagination
    {...pagination}
    current={page}
    pageSize={pageSize}
    total={rowCount}
    onChange={onChange}
    {...restProps}
  />
);

export default Pagination;
