import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { ArrowLeftOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import { DatePicker } from 'antd';
import qs from 'qs';

import api from 'services/API';
import { commonUtilities } from 'utils';
import { useQuery, useQueryParams } from 'hooks';

import { Pagination, Table } from 'components';
import { userKey, columns, initSorter } from './settings';

const { isNotEmptyArray } = commonUtilities;
const { RangePicker } = DatePicker;

const Leaderboard = () => {
  const { gameId } = useParams();
  const [prevQP, setPrevQP] = useState(null);
  const { data: fetchedLeaders, refetch } = useQuery(
    'leaders',
    async () =>
      await api.getGameLeaders(gameId, queryParams).then(({ data, pagination }) => {
        setPagination(pagination);
        return data;
      }),
  );

  const {
    pagination,
    setPagination,
    sorter,
    queryParams,
    prevQueryParams,
    handleChangeTable,
    changeDateTimeRange,
  } = useQueryParams(refetch, { initSorter });

  const reHandleChangeTable = (newPagination, filters = {}, sorter) => {
    const preparedFilters = {
      ...filters,
      prize:
        (commonUtilities.isNotEmptyArray(filters.prize) && filters.prize.length === 2) || null
          ? null
          : filters.prize,
    };
    handleChangeTable(newPagination, preparedFilters, sorter);
  };

  useEffect(() => {
    if (!prevQP) {
      setPrevQP(prevQueryParams);
    }
  }, [prevQP, prevQueryParams]);

  const leaders = useMemo(
    () =>
      isNotEmptyArray(fetchedLeaders)
        ? fetchedLeaders.map(leader => ({
            ...leader,
            key: leader.rating,
          }))
        : [],
    [fetchedLeaders],
  );

  const tableColumns = useCallback(
    () =>
      columns.map((column, index) => {
        if (
          (isNotEmptyArray(sorter) && !!sorter.find(({ field }) => field === column.key)) ||
          sorter.field === column.key
        ) {
          const columnSorter = isNotEmptyArray(sorter)
            ? { ...sorter.find(({ field }) => field === column.key) }
            : sorter;
          column.defaultSortOrder = columnSorter.order;
          column.sorter = columnSorter;
        }

        if (column.key === userKey) {
          const searchWords = !isEmpty(queryParams._where) && queryParams._where[userKey];

          return {
            ...column,
            render: ({ username }) =>
              isNotEmptyArray(searchWords) ? (
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={searchWords}
                  autoEscape
                  textToHighlight={username ? username.toString() : ''}
                />
              ) : (
                username
              ),
          };
        } else if (column.key === 'created_at') {
          return {
            ...column,
            title: (
              <RangePicker
                className="range-picker"
                style={{ maxWidth: 320 }}
                bordered={false}
                placeholder={['From', 'To']}
                showTime
                format="HH:mm MM/DD/YYYY"
                onChange={changeDateTimeRange}
              />
            ),
          };
        } else if (column.key === 'ratingUsers') {
          return {
            ...column,
            render: () => index,
          };
        }
        return column;
      }),
    [sorter, queryParams, changeDateTimeRange],
  );

  return (
    <>
      <div className="page-controls">
        <Link to={`/games?${qs.stringify(prevQP)}`}>
          <ArrowLeftOutlined /> Games
        </Link>
        <Pagination
          {...pagination}
          onChange={(current, pageSize) => handleChangeTable({ current, pageSize }, {}, sorter)}
        />
      </div>
      <Table
        columns={tableColumns()}
        dataSource={leaders}
        pagination={{ ...pagination, position: ['bottomRight'] }}
        onChange={reHandleChangeTable}
      />
    </>
  );
};

export default Leaderboard;
