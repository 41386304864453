import { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { AuthContext } from 'hooks/auth';

const AuthRoute = ({ children, ...rest }) => {
  const { authState } = useContext(AuthContext);

  if (authState.jwt) {
    return <Redirect to="/" />;
  }

  return <Route {...rest}>{children}</Route>;
};

export default AuthRoute;
