import styled from 'styled-components';

export const Wrapper = styled.div`
  .top-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .info-alert {
    &.ant-alert-with-description {
      align-items: center;
      padding: 6px 16px;
    }
    .ant-alert-message {
      display: none;
    }
  }
  .info-wrapper {
    display: flex;
    .info {
      font-size: 16px;
      margin-right: 16px;
      .ant-tag {
        font-size: 16px;
        line-height: 23px;
        font-family: 'Fredoka One', Arial, Helvetica, sans-serif;
        margin-right: 0;
      }
    }
  }
  label {
    font-size: 16px;
    font-family: 'Fredoka One', Arial, Helvetica, sans-serif;
  }
  .images {
    display: flex;
    justify-content: center;
  }
  canvas {
    border: 1px solid #000000;
  }
`;

export const Arrows = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 16px;

  .arrow-up,
  .arrow-down {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .arrow-up {
    transform: translate(-50%, calc(-50% - 40px));
  }
  .arrow-down {
    transform: translate(-50%, calc(-50% + 40px));
  }
  .arrow-left {
    margin-right: 15px;
  }
  .arrow-right {
    margin-left: 15px;
  }
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
