import { Modal as AntModal, Typography } from 'antd';

const Modal = ({ title, children, ...restProps }) => {
  return (
    <AntModal
      title={
        <Typography.Title level={3} style={{ marginBottom: 0 }}>
          {title}
        </Typography.Title>
      }
      centered
      {...restProps}
    >
      {children}
    </AntModal>
  );
};

export default Modal;
