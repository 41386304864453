import { Typography } from 'antd';

import { Wrapper } from './styles';

const AuthWrapper = ({ children }) => (
  <Wrapper>
    <Typography.Title level={1} style={{ fontSize: 56 }}>
      SpotMy
    </Typography.Title>
    {children}
  </Wrapper>
);

export default AuthWrapper;
