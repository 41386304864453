import { useQuery as useReactQuery } from 'react-query';

const useQuery = (queryKey = '', queryFn = () => null, options = {}) => {
  return useReactQuery(queryKey, queryFn, {
    refetchOnWindowFocus: false,
    ...options,
  });
};

export default useQuery;
