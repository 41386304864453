import styled, { css } from 'styled-components';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

const triggerCommonCss = css`
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #1890ff;
  }
`;

export const TriggerOpened = styled(MenuUnfoldOutlined)`
  ${triggerCommonCss}
`;

export const TriggerClosed = styled(MenuFoldOutlined)`
  ${triggerCommonCss}
`;
