import {
  ArrowDownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
} from '@ant-design/icons';

export const defaults = {
  img1Id: 'img1',
  img2Id: 'img2',
  initCanvas: {
    maxSize: 500,
    width: 500,
    height: 500,
    unitScaleTransform: true,
    defaultCursor: 'crosshair',
  },
  rectOptions: {
    width: 40,
    height: 40,
    left: 15,
    top: 15,
    fill: 'transparent',
    stroke: '#000000',
    strokeWidth: 2,
    cornerColor: '#000000',
    centeredScaling: true,
  },
  notEditableRectOptions: {
    lockMovementX: true,
    lockMovementY: true,
    hoverCursor: 'default',
    selectable: false,
  },
  rectControlsVisibility: {
    bl: true,
    br: true,
    tl: true,
    tr: true,
    mt: false,
    mb: false,
    ml: false,
    mr: false,
    mtr: false,
  },
  minSize: 40,
  minScale: 1,
};

export const arrows = [
  {
    icon: <ArrowLeftOutlined />,
    className: 'arrow-left',
    coords: [-10, 0],
  },
  {
    icon: <ArrowUpOutlined />,
    className: 'arrow-up',
    coords: [0, -10],
  },
  {
    icon: <ArrowDownOutlined />,
    className: 'arrow-down',
    coords: [0, 10],
  },
  {
    icon: <ArrowRightOutlined />,
    className: 'arrow-right',
    coords: [10, 0],
  },
];
