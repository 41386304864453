import styled from 'styled-components';
import { Layout } from 'antd';

export const Header = styled(Layout.Header)`
  display: flex;
  padding: 0 24px;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
`;

export const Content = styled(Layout.Content)`
  min-height: 280px;
  margin: 24px 16px;
  padding: 24px;
  background-color: #fff;
`;
