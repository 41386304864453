import { notification } from 'config';

const httpErrorHandler = err => {
  if (err?.response) {
    switch (err.response.data.data.errorId) {
      case 5001:
        notification.error({
          message: 'The game was updated. Please, reload the page',
        });
        break;
      case 5002:
        notification.error({
          message: 'Sorry, the game was deleted',
        });
        break;
      default:
        notification.error({
          message: err?.response?.data?.message || err?.response?.statusText || err?.message,
        });
    }
  }
};

export default httpErrorHandler;
