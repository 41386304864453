import { Input, InputNumber, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { Button } from 'components';

const FilterSearchDropdown = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  searchBy,
}) => {
  const { placeholder, number = false } = searchBy;

  return (
    <div style={{ padding: 8 }}>
      {number ? (
        <InputNumber
          min={1}
          type="number"
          placeholder={`Search ${placeholder}`}
          value={selectedKeys[0]}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={value => setSelectedKeys(value ? [value] : [])}
          onPressEnter={() => confirm()}
        />
      ) : (
        <Input
          placeholder={`Search ${placeholder}`}
          value={selectedKeys[0]}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
        />
      )}
      <Space>
        <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
          Reset
        </Button>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
      </Space>
    </div>
  );
};

export default FilterSearchDropdown;
