export const columns = [
  {
    key: 'username',
    title: 'Username',
    dataIndex: 'username',
  },
  {
    key: 'email',
    title: 'Email',
    dataIndex: 'email',
  },
  {
    key: 'gamesCount',
    title: 'Created Games',
    dataIndex: 'gamesCount',
    align: 'center',
    sorter: {
      multiple: 1,
    },
  },
  {
    key: 'followersCount',
    title: 'Followers',
    dataIndex: 'followersCount',
    align: 'center',
    sorter: {
      multiple: 2,
    },
  },
  {
    key: 'playedGamesCount',
    title: 'Played Games',
    dataIndex: 'playedGamesCount',
    align: 'center',
    sorter: {
      multiple: 3,
    },
  },
  {
    key: 'winnersCount',
    title: 'Received Prizes',
    dataIndex: 'winnersCount',
    align: 'center',
    sorter: {
      multiple: 4,
    },
  },
  {
    key: 'deleting',
    align: 'center',
  },
];

export const initSorter = {
  field: 'gamesCount',
  order: 'descend',
};
