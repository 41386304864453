import { format } from 'date-fns';
import { Image } from 'antd';

import { FilterSearchIcon, FilterSearchDropdown } from 'components';
import fallback from 'assets/images/fallback.svg';
import { ImageWrapper } from '../Games/GameEdit/styles';

export const creatorKey = 'level.game.creator.username_contains';

export const columns = [
  {
    key: 'image',
    title: 'Image',
    dataIndex: 'url',
    align: 'center',
    render: url => (
      <ImageWrapper className="small">
        <Image width={40} height={40} src={url || fallback} />
      </ImageWrapper>
    ),
  },
  {
    key: creatorKey,
    title: 'Username',
    dataIndex: 'creator',
    filterIcon: filtered => <FilterSearchIcon filtered={filtered} />,
    filterDropdown: props => (
      <FilterSearchDropdown {...props} searchBy={{ placeholder: 'by username' }} />
    ),
  },
  {
    key: 'level.game',
    title: "Game's ID",
    dataIndex: 'gameId',
    align: 'center',
    filterIcon: filtered => <FilterSearchIcon filtered={filtered} />,
    filterDropdown: props => (
      <FilterSearchDropdown {...props} searchBy={{ placeholder: 'by ID', number: true }} />
    ),
  },
  {
    key: 'created_at',
    dataIndex: 'created_at',
    align: 'center',
    render: date => format(new Date(date).getTime(), 'MM/dd/yyyy'),
  },
  {
    key: 'download',
    title: 'Download',
    align: 'center',
  },
];
