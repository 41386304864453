import { useCallback, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { CloseCircleOutlined } from '@ant-design/icons';

import api from 'services/API';
import { notification } from 'config';
import { commonUtilities } from 'utils';
import { useQuery, useQueryParams } from 'hooks';

import { Table, Button, PopConfirm } from 'components';
import { columns, initSorter } from './settings';

const { isNotEmptyArray } = commonUtilities;

const Users = () => {
  const queryClient = useQueryClient();
  const { data: fetchedUsers, refetch } = useQuery(
    'users',
    async () =>
      await api.getUsers(queryParams).then(({ data, pagination }) => {
        setPagination(pagination);
        return data;
      }),
    {},
  );

  const {
    pagination,
    setPagination,
    sorter,
    queryParams,
    handleChangeTable,
  } = useQueryParams(refetch, { initSorter });

  const users = useMemo(() => {
    if (isNotEmptyArray(fetchedUsers)) {
      return fetchedUsers.map(user => ({ ...user, key: user.id }));
    }
    return [];
  }, [fetchedUsers]);

  const deletingUser = useMutation(id => api.deleteUser(id), {
    onSuccess: ({ id }) => {
      queryClient.setQueryData('users', old => {
        return old.filter(user => user.id !== id);
      });
      refetch();
      notification.success({
        message: 'The user has been deleted successfully',
      });
    },
  });

  const deleteUser = useCallback(userID => deletingUser.mutate(userID), [deletingUser]);

  const tableColumns = useCallback(
    () =>
      columns.map(column => {
        if (
          (isNotEmptyArray(sorter) && !!sorter.find(({ field }) => field === column.key)) ||
          sorter.field === column.key
        ) {
          const columnSorter = isNotEmptyArray(sorter)
            ? { ...sorter.find(({ field }) => field === column.key) }
            : sorter;
          column.defaultSortOrder = columnSorter.order;
          column.sorter = { ...columnSorter, multiple: column.sorter.multiple };
        }
        if (column.key === 'deleting') {
          column.render = ({ id }) => (
            <PopConfirm
              icon={<CloseCircleOutlined style={{ color: 'red' }} />}
              titleContent="You won't be able to recover this user!"
              okText="Delete"
              okButtonProps={{ type: 'danger' }}
              onConfirm={() => deleteUser(id)}
            >
              <Button danger>Delete</Button>
            </PopConfirm>
          );
          return column;
        }
        return column;
      }),
    [deleteUser, sorter],
  );

  return (
    <Table
      columns={tableColumns()}
      dataSource={users}
      pagination={{ ...pagination, position: ['topRight', 'bottomRight'] }}
      onChange={handleChangeTable}
    />
  );
};

export default Users;
