import { commonUtilities } from 'utils';

export const registrationForm = {
  settings: {
    name: 'registration',
    validateTrigger: 'onSubmit',
  },
  usernameField: {
    name: 'username',
    initialValue: '',
    rules: [{ required: true, message: 'The username field cannot be blank' }],
    normalize: value => value.trim(),
  },
  passwordField: {
    name: 'password',
    initialValue: '',
    rules: [
      { required: true, message: 'The password field cannot be blank' },
      {
        min: 8,
        message: "Password can't be less than 8 characters.",
      },
      { max: 24, message: "Password can't be longer than 24 characters" },
      {
        pattern: commonUtilities.passwordRegExp(),
        message: 'Password must have at least one digit, uppercase and lowercase symbol',
      },
    ],
  },
  passwordConfirmationField: {
    name: 'passwordConfirmation',
    initialValue: '',
    dependencies: ['password'],
    rules: [
      {
        required: true,
        message: 'The password field cannot be blank',
      },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (!value || getFieldValue('password') === value) {
            return Promise.resolve();
          }
          return Promise.reject('The two passwords that you entered do not match!');
        },
      }),
    ],
  },
  submitButton: {
    type: 'primary',
    htmlType: 'submit',
    block: true,
  },
};
