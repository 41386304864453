import React, { useContext } from 'react';
import { BrowserRouter as Router, Redirect, Switch, Route } from 'react-router-dom';

import { AuthContext } from 'hooks/auth';

import {
  LoginPage,
  RegistrationPage,
  AdminsPage,
  UsersPage,
  ImagesPage,
  GamesPage,
  GameEditPage,
  LeaderboardPage,
} from 'pages';
import { AuthRoute, Layout } from 'components';

const App = () => {
  const authContext = useContext(AuthContext);

  if (authContext.authLoading) {
    return null;
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/admins" />
        </Route>
        <AuthRoute exact path="/login">
          <LoginPage {...authContext} />
        </AuthRoute>
        <AuthRoute exact path="/admins/confirmation">
          <RegistrationPage />
        </AuthRoute>
        <Layout canActivate={authContext.authState.jwt}>
          <Route exact path="/admins">
            <AdminsPage />
          </Route>
          <Route exact path="/users">
            <UsersPage />
          </Route>
          <Route exact path="/images">
            <ImagesPage />
          </Route>
          <Route exact path="/games">
            <GamesPage />
          </Route>
          <Route exact path="/games/:gameId">
            <GameEditPage />
          </Route>
          <Route exact path="/games/:gameId/leaderboard">
            <LeaderboardPage />
          </Route>
          {/*<Route path="*">
            <Redirect to="/admins" />
          </Route>*/}
        </Layout>
      </Switch>
    </Router>
  );
};

export default App;
