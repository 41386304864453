import { useCallback, useState, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Form, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import api from 'services/API';
import { notification } from 'config';
import { commonUtilities } from 'utils';
import { useQuery, useQueryParams } from 'hooks';

import { Modal, Table, Button, Pagination } from 'components';
import { columns, initSorter, invitationForm } from './settings';

const { isNotEmptyArray } = commonUtilities;
const { emailField } = invitationForm;

const Admins = () => {
  const { data: fetchedAdmins, refetch } = useQuery(
    'admins',
    async () =>
      await api.getAdmins(queryParams).then(({ data, pagination }) => {
        setPagination(pagination);
        return data;
      }),
    {},
  );

  const {
    pagination,
    setPagination,
    sorter,
    queryParams,
    handleChangeTable,
  } = useQueryParams(refetch, { initSorter });

  const admins = useMemo(() => {
    if (isNotEmptyArray(fetchedAdmins)) {
      return fetchedAdmins.map(admin => ({ ...admin, key: admin.id }));
    }
    return [];
  }, [fetchedAdmins]);

  const [modalVisibility, setModalVisibility] = useState(false);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const invitingAdmin = useMutation(email => api.inviteAdmin([email]), {
    onSuccess: newAdmin => {
      queryClient.setQueryData('admins', old => [...old, ...newAdmin]);
      setModalVisibility(false);
      form.resetFields();
      notification.success({
        message: 'The invitation is sent successfully!',
      });
    },
    onError: () => closeModal(),
  });

  const reInvitingAdmin = useMutation(email => api.reInviteAdmin({ email }), {
    onSuccess: () => {
      notification.success({
        message: 'The invitation is re-sent successfully!',
      });
    },
  });

  const openModal = () => setModalVisibility(true);

  const closeModal = () => {
    setModalVisibility(false);
    form.resetFields();
  };

  const onSubmit = () =>
    form
      .validateFields()
      .then(({ email }) => invitingAdmin.mutate(email))
      .catch(err => {
        // console.log('Validate Failed:', info);
      });

  const reSendInvitation = useCallback(email => reInvitingAdmin.mutate(email), [reInvitingAdmin]);

  const tableColumns = useCallback(
    () =>
      columns.map(column => {
        if (
          (isNotEmptyArray(sorter) && !!sorter.find(({ field }) => field === column.key)) ||
          sorter.field === column.key
        ) {
          const columnSorter = isNotEmptyArray(sorter)
            ? { ...sorter.find(({ field }) => field === column.key) }
            : sorter;
          column.defaultSortOrder = columnSorter.order;
          column.sorter = { ...columnSorter, multiple: column.sorter.multiple };
        }
        if (column.key === 'invitation') {
          column.render = (confirmed, { email }) =>
            confirmed ? null : (
              <Button type="primary" onClick={() => reSendInvitation(email)}>
                Re-send
              </Button>
            );
          return column;
        }
        return column;
      }),
    [sorter, reSendInvitation],
  );

  return (
    <>
      <div className="page-controls">
        <Button type="primary" onClick={openModal}>
          Invite Admin
        </Button>
        <Pagination
          {...pagination}
          onChange={(current, pageSize) => handleChangeTable({ current, pageSize }, {}, sorter)}
        />
      </div>
      <Table
        columns={tableColumns()}
        dataSource={admins}
        pagination={{ ...pagination, position: ['bottomRight'] }}
        onChange={handleChangeTable}
      />
      {modalVisibility && (
        <Modal
          title="Inviting Admin"
          visible={modalVisibility}
          okText="Send invitation"
          onOk={onSubmit}
          onCancel={closeModal}
          confirmLoading={invitingAdmin.isLoading}
        >
          <Form {...invitationForm.settings} form={form}>
            <Form.Item {...emailField}>
              <Input type="text" size="large" placeholder="Email" prefix={<UserOutlined />} />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default Admins;
