import { useMutation } from 'react-query';
import { Form, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import api from 'services/API';

import { AuthWrapper, Button } from 'components';
import { loginForm } from './settings';

const { emailField, passwordField, submitButton } = loginForm;

const Login = ({ setAuthState }) => {
  const authentication = useMutation(data => api.login(data), {
    onSuccess: (data, variables, context) => {
      if (data) {
        setAuthState(data);
      }
    },
  });

  const onSubmit = values => {
    authentication.mutate(values);
  };

  return (
    <AuthWrapper>
      <Form {...loginForm.settings} onFinish={onSubmit}>
        <Form.Item {...emailField}>
          <Input type="text" size="large" placeholder="Email" prefix={<UserOutlined />} />
        </Form.Item>
        <Form.Item {...passwordField}>
          <Input.Password size="large" placeholder="Password" prefix={<LockOutlined />} />
        </Form.Item>
        <Button {...submitButton} loading={authentication.isLoading}>
          Log in
        </Button>
      </Form>
    </AuthWrapper>
  );
};

export default Login;
