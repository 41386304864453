import { Popconfirm as AntPopConfirm, Typography } from 'antd';

const PopConfirm = ({
  titleHeading = 'Are you sure?',
  titleContent = '',
  placement = 'topRight',
  cancelText = 'Cancel',
  cancelButtonProps = { type: 'primary' },
  children,
  ...restProps
}) => (
  <AntPopConfirm
    title={
      <>
        <Typography.Title level={4}>{titleHeading}</Typography.Title>
        <p style={{ fontSize: 16 }}>{titleContent}</p>
      </>
    }
    placement={placement}
    cancelText={cancelText}
    cancelButtonProps={cancelButtonProps}
    {...restProps}
  >
    {children}
  </AntPopConfirm>
);

export default PopConfirm;
