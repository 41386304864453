import { useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import qs from 'qs';

import { initPagination } from 'config/pagination';
import { commonUtilities } from 'utils';
import { format } from 'date-fns';

const { isNotEmptyArray } = commonUtilities;

const useQueryParams = (refetch, settings = {}) => {
  const { initSorter = {} } = settings;
  const history = useHistory();
  const { pathname, search, prevQueryParams } = useLocation();

  const parsedSearch = qs.parse(search.slice(1));

  const [pagination, setPagination] = useState(() => {
    return {
      ...initPagination,
      ...(parsedSearch?.page && { page: Number(parsedSearch.page) }),
      ...(parsedSearch?.pageSize && { pageSize: Number(parsedSearch.pageSize) }),
    };
  });

  const [filters, setFilters] = useState(parsedSearch._where);

  const [sorter, setSorter] = useState(() => {
    if (parsedSearch?._sort) {
      const isMultiSorter = parsedSearch._sort.includes(',');
      const splitSorter = isMultiSorter ? parsedSearch._sort.split(',') : [parsedSearch._sort];
      return splitSorter.map(sort => {
        const splitSort = sort.split(':');
        return {
          field: splitSort[0],
          order: splitSort[1] === 'ASC' ? 'ascend' : 'descend',
        };
      });
    } else {
      return initSorter;
    }
  });

  const [dateRange, setDateRange] = useState(() => {
    if (parsedSearch.created_at_d_start && parsedSearch.created_at_d_end) {
      return {
        created_at_d_start: parsedSearch.created_at_d_start,
        created_at_d_end: parsedSearch.created_at_d_end,
      };
    } else return null;
  });

  const [dateTimeRange, setDateTimeRange] = useState(() => {
    if (parsedSearch.created_at_d_start && parsedSearch.created_at_d_end) {
      return {
        created_at_dt_start: parsedSearch.created_at_dt_start,
        created_at_dt_end: parsedSearch.created_at_dt_end,
      };
    } else return null;
  });

  const queryParams = useMemo(() => {
    const preparedFilters = pickBy(filters, identity);
    const sorterString = (Array.isArray(sorter) ? sorter : [sorter])
      .filter(sort => sort.field && sort.order)
      .map(sort => `${sort.field}:${sort.order === 'ascend' ? 'ASC' : 'DESC'}`)
      .join(',');
    return {
      page: pagination.page,
      pageSize: pagination.pageSize,
      ...(preparedFilters && { _where: preparedFilters }),
      ...(sorterString && { _sort: sorterString }),
      ...(dateRange && dateRange),
      ...(dateTimeRange && dateTimeRange),
    };
  }, [pagination, filters, sorter, dateRange, dateTimeRange]);

  useEffect(() => {
    history.push(`${pathname}?${qs.stringify(queryParams)}`);
  }, [history, pathname, queryParams]);

  useEffect(() => {
    refetch();
  }, [search, refetch]);

  const handleChangeTable = (newPagination = {}, filters = {}, sorter = {} || []) => {
    setPagination({
      ...pagination,
      ...(newPagination?.current && { page: newPagination.current }),
      ...(newPagination?.pageSize && { pageSize: newPagination.pageSize }),
    });
    setFilters(filters);
    setSorter(sorter);
  };

  const changeDateRange = dates => {
    const range = isNotEmptyArray(dates)
      ? {
          created_at_d_start: format(new Date(dates[0]._d).getTime(), 'yyyy-MM-dd'),
          created_at_d_end: format(new Date(dates[1]._d).getTime(), 'yyyy-MM-dd'),
        }
      : null;
    setDateRange(range);
  };

  const changeDateTimeRange = dates => {
    const range = isNotEmptyArray(dates)
      ? {
          created_at_dt_start: new Date(dates[0]._d).toISOString(),
          created_at_dt_end: new Date(dates[1]._d).toISOString(),
        }
      : null;
    setDateTimeRange(range);
  };

  const clearFilters = () => {
    setFilters(null);
    if (!dateRange) {
      setDateRange(null);
    }
    if (!dateTimeRange) {
      setDateTimeRange(null);
    }
    history.push(pathname);
  };

  return {
    pagination,
    setPagination,
    filters,
    sorter,
    dateRange,
    dateTimeRange,
    queryParams,
    prevQueryParams,
    handleChangeTable,
    changeDateRange,
    changeDateTimeRange,
    clearFilters,
  };
};

export default useQueryParams;
