import qs from 'qs';
import { useMutation } from 'react-query';
import { useLocation, useHistory } from 'react-router-dom';
import { Form, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import api from 'services/API';
import notification from 'config/notification';

import { AuthWrapper, Button } from 'components';
import { registrationForm } from './settings';

const { usernameField, passwordField, passwordConfirmationField, submitButton } = registrationForm;

const Registration = () => {
  const location = useLocation();
  const history = useHistory();
  const registration = useMutation(data => api.confirmRegistration(data), {
    onSuccess: () => {
      notification.success({
        message: 'Your registration was successfully!',
      });
      history.push('/login');
    },
  });

  const onSubmit = values => {
    const confirmationToken = qs.parse(location.search.slice(1)).token;
    registration.mutate({ ...values, confirmationToken });
  };

  return (
    <AuthWrapper>
      <Form {...registrationForm.settings} onFinish={onSubmit}>
        <Form.Item {...usernameField}>
          <Input size="large" placeholder="Username" prefix={<UserOutlined />} />
        </Form.Item>
        <Form.Item {...passwordField}>
          <Input.Password size="large" placeholder="Password" prefix={<LockOutlined />} />
        </Form.Item>
        <Form.Item {...passwordConfirmationField}>
          <Input.Password size="large" placeholder="Confirm password" prefix={<LockOutlined />} />
        </Form.Item>
        <Button {...submitButton} loading={registration.isLoading}>
          Confirm registration
        </Button>
      </Form>
    </AuthWrapper>
  );
};

export default Registration;
