import { UserOutlined, TeamOutlined, PictureOutlined, AppstoreOutlined } from '@ant-design/icons';

export const menu = [
  {
    title: 'Admins',
    icon: <UserOutlined />,
    link: '/admins',
  },
  {
    title: 'Users',
    icon: <TeamOutlined />,
    link: '/users',
  },
  {
    title: 'Games',
    icon: <AppstoreOutlined />,
    link: '/games',
  },
  {
    title: 'Images',
    icon: <PictureOutlined />,
    link: '/images',
  },
];
