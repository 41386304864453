import { useState, createContext, useEffect } from 'react';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [state, setState] = useState({ jwt: '', user: {} });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const rawData = localStorage.getItem('spot_my_admin');
    const data = JSON.parse(rawData);

    if (data) {
      setState(data);
    }

    setLoading(false);
  }, []);

  // todo: you *might* need to memoize this value (learn more in http://kcd.im/optimize-context)
  const setAuthState = data => {
    localStorage.setItem('spot_my_admin', JSON.stringify(data));
    setState(data);
  };

  const value = { authState: state, authLoading: loading, setAuthState };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthProvider };
